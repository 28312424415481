<template>
  <div class="h-100vh d-flex flex-column justify-content-center align-items-center user-registeration">
    <stores />
<!--    <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"-->
<!--                :leave-active-class="`animated ${animated.exit}`">-->
<!--      <keep-alive>-->
<!--        <component :is="selectedComponent" @changeForm="changeComponent"></component>-->
<!--      </keep-alive>-->
<!--    </transition>-->
  </div>
</template>
<script>
// import registerAs from '../components/registerationSteps/registerAs'
import stores from './index'
// import vete from '../components/registerationSteps/vete/vete'
// import transportation from '../components/registerationSteps/transportation/transportation'
import { core } from '@/config/pluginInit'
export default {
  components: { stores },

  mounted () {
    core.index()
  },
  methods: {
    changeComponent (data) {
      console.log(data)
      const indexStepper = this.allComponent.findIndex(stepper => stepper.value === data)
      this.selectedComponent = this.allComponent[indexStepper].value
      this.selectedComponentTitle = this.allComponent[indexStepper].title
    }
  }
}
</script>
<style>

</style>
