import { joining, transportation, veterinary } from '@/axios';

export default {
  joinMerchant (payload) {
    return joining().post('merchant/signup', payload);
  },
  joinDriver (payload) {
    return transportation().post('drivers', payload);
  },
  joinCompany (payload) {
    return transportation().post('companies', payload);
  },
  joinDoctor (payload) {
    return veterinary().post('doctors/register', payload);
  },
  joinClinic (payload) {
    return veterinary().post('clinics/register', payload);
  }
};
