<template>
  <div class="w-100">
    <div class="websiteWrapper">
      <b-row class="justify-content-center">
        <b-col md="12">
          <header>
            <h2>{{ $t("auth.joinUsSeller") }}</h2>
            <p>
              {{ $t("auth.joinAsSellerParagraph") }}
            </p>
          </header>
          <div class="join-form">
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form
                class="position-relative form-top py-4"
                @submit.prevent="handleSubmit(joinMerchant)"
              >
                <b-row>
                  <b-col md="12">
                    <input-form
                      v-model="merchant.name"
                      validate="required"
                      :name="$t('auth.fullName')"
                      :placeholder="$t('auth.fullName')"
                    />
                  </b-col>

                  <b-col md="12">
                    <input-form
                      v-model="merchant.email"
                      validate="required|email"
                      :name="$t('auth.email')"
                      :placeholder="$t('auth.email')"
                    />
                  </b-col>
                  <b-col md="12">
                    <country-code
                      :validate="'required|numeric|min:9|max:11'"
                      :name="$t('auth.phoneNumber')"
                      :placeholder="$t('auth.phoneNumber')"
                      v-model="merchant.phone"
                      id="phone_number_clinic"
                      @onSelect="onSelect"
                    />
                  </b-col>
                  <b-col md="12">
                    <input-form
                      v-model="merchant.national_id"
                      validate="required|numeric|max:10"
                      :name="$t('endUser.IdNumber')"
                      :placeholder="$t('endUser.IdNumber')"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <input-form
                      v-model="merchant.store_name"
                      :validate="'required'"
                      :name="$t('endUser.storeName')"
                      :placeholder="$t('endUser.storeName')"
                    />
                  </b-col>
                  <b-col md="12">
                    <main-select
                      :options="cities"
                      class=""
                      :placeholder="$t('main.city')"
                      label="name"
                      :reduce="(data) => +data.id"
                      v-model="city_id"
                      validate="required"
                      :name="$t('main.city')"
                    />
                  </b-col>
                  <b-col md="12">
                    <main-select
                      :options="areas"
                      class=""
                      :placeholder="$t('main.area')"
                      label="name"
                      :reduce="(data) => +data.id"
                      v-model="area_id"
                      validate="required"
                      :name="$t('main.area')"
                    />
                  </b-col>
                  <b-col md="12">
                    <input-form
                      v-model="merchant.store_address"
                      :validate="'required'"
                      :name="$t('endUser.storeAddress')"
                      :placeholder="$t('endUser.storeAddress')"
                      type="text"
                    />
                  </b-col>
                  <b-col cols="12">
                    <input-form
                      class="mb-3"
                      type="password"
                      :validate="'required|min:8'"
                      v-model="merchant.password"
                      :placeholder="$t('auth.password')"
                      :name="$t('auth.password')"
                      vid="password"
                    />
                  </b-col>
                  <b-col cols="12">
                    <input-form
                      class="mb-3"
                      type="password"
                      v-model="merchant.confirm_password"
                      :validate="'required|confirmed:password'"
                      :placeholder="$t('auth.passwordChecked')"
                      :name="$t('auth.passwordChecked')"
                    />
                  </b-col>

                  <b-col class="d-flex align-items-center" cols="12">
                    <input
                      type="checkbox"
                      v-model="merchant.accept_terms"
                      :required="true"
                      :name="$t('auth.acceptTerms')"
                      style="
                        margin-inline-end: 10px;
                        accent-color: var(--iq-primary);
                      "
                    />
                    <a
                      href="#"
                      @click.prevent="termsModal = true"
                      style="text-decoration: underline"
                    >
                      {{ $t("auth.acceptTerms") }}</a
                    >
                  </b-col>
                </b-row>
                <app-button
                  class="mt-2"
                  :loading="loadingRegistrationButton"
                  type="submit"
                >
                  {{ $t("auth.create") }}
                </app-button>
              </b-form>
            </ValidationObserver>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
      size="lg"
      hide-footer
      centered
      :title="$t('auth.termsConditions')"
      v-model="termsModal"
    >
      <div class="p-3">
        مقدمة: تتعلق هذه سياسة الشروط والاحكام (“سياسة الاستخدام”) باستخدامك "
        تطبيق سوق العطارة/الموقع" كتاجر المتوفر على الرابط: ((……………….. تطبيق
        منصة سوق العطارة المملوكة لشركة سوق العطارة للتجارة وهو منصة متخصصة
        بتقديم كافة الخدمات المتعلقةبعرض بمنتجات العطارة بغرض التداول بين
        الموردين والموزعين والمشترين في المملكة العربية السعودية. تطبيق سوق
        العطارة يمثل محطة التداول لمنتجات العطارة بين الموردين والموزعين
        والمشترين من خلال المنصة وأدوات حديثة لعرض، حيث يتم تداول المنتجات من
        خلال المنصة حسب العرض والطلب مع عرض السعر والكمية وعمليات البيع والشراء
        من خلال المنصة. كما تعمل منصة سوق العطارة على تنمية التراث الطبيعي وفتح
        منافذ وأسواق داخلية وخارجية للمنتجات العطارة عالية الجودة، حيث ترتكز
        المنصة على تقديم أكثر منتجات العطارة اعتمادية في السوق للوفاء باحتياجات
        العملاء المستخدمين للتطبيقللمنصة، مع المراعاة لجميع الشروط الفنية
        والتقنية والحرص على أعلى معايير الجودة أاثناء التعامل على المنصة. شركة
        سوق العطارة للتجارة هي شركة سعودية تعمل في مجال التقنية وتقديم الحلول
        الإلكترونية لمواكبة التحول الرقمي في المملكة العربية السعودية. تأسست
        وفقاً للأنظمة المعمول بها في المملكة العربية السعودية، وعنوانها: طريق
        الأمير محمد بن سعد بن عبد العزيز، حي الملقا، الرياض 2752، المملكة
        العربية السعودية (يشار إليها في هذه الاتفاقية بـ ” منصة/ تطبيق سوق
        العطارة” وبضمير المتكلم). تتكون هذه الشروط والأحكام من هيكل رئيسي وسياسة
        الخصوصية، بالإضافة إلى أي قواعد وتعليمات وما إلى ذلك يتم نشرها على هذا
        الموقع أو التي قد يتم نشرها في المستقبل، من خلال الوصول إلى (أوافق) أو
        تنزيل أو استخدام لقبول أي من خدمات تطبيق سوق العطارة، فإنك توافق على
        الالتزام بهذه الشروط والاحكام والأحكام وسياسة الخصوصية. توجد في هذه
        الاتفاقية "الشروط والأحكام" المُنظّمة لاستخدامك كتاجر لتطبيق/ موقع سوق
        العطارة وكافة الآثار القانونية التي تنتج عن استخدامك لخدماتها، حيث أن
        استخدامك للتطبيق للمنصة يُعد موافقة وقبول منك وأنت بكامل أهليتك المعتبرة
        شرعاً ونظاماً وقانوناً لكافة مواد وبنود وأحكام هذه الاتفاقية وهو تأكيد
        لالتزامك بأنظمتها ولما ذُكر فيها، ونشير إليكم بأن " سوق العطارة " قد
        يكون عبارة عن ( موقع إلكتروني أو تطبيق على الهواتف المحمولة) وتعتبر هذه
        الاتفاقية سارية المفعول ونافذة بمجرد قيامكم بالموافقة عليها عند البدء في
        التسجيل، كما يرجى التأكد من الاطلاع على أي تغيرات من خلال زيارة موقعنا
        الالكتروني بصورة منتظمة. أنت توافق على الالتزام بأي تعديلات على الشروط
        والأحكام وتوافق على أن منصة سوق العطارة ليست ملزمة بإخطارك بهذه
        التغييرات. كما تقر بإأنه من مسؤوليتك مراجعة هذه الشروط لأية تعديلات
        بانتظام، كما أن الاستخدام المستمر للموقع والخدمات التي تقدمها المنصة بعد
        نشر أي تغييرات على الشروط تعني موافقتك على هذه التغييرات. التعريفات:
        يتعتبر المقدمة التمهيد أعلاه جزءاً لا يتجزأ من هذه الاتفاقية، كما تجدون
        أدناه الدلالات والتعريفات للعبارات الرئيسية المستخدمة في هذه الاتفاقية.
        شركة سوق العطارة للتجارة: هي شركة سعودية تعمل في مجال التقنية وتقديم
        الحلول الإلكترونية لمواكبة التحول الرقمي في المملكة العربية السعودية.
        تأسست وفقاً للأنظمة المعمول بها في المملكة العربية السعودية، وعنوانها:
        طريق الأمير محمد بن سعد بن عبد العزيز، حي الملقا، الرياض 2752، المملكة
        العربية السعودية (يشار إليها في هذه الاتفاقية بـ ” منصة/ تطبيق سوق
        العطارة” وبضمير المتكلم). سوق العطارة: منصة لعرض أسعار ومؤشرات تداول
        منتجات العطارة وتمثل محطة التداول بين الموردين والتجار والعملاء المسجلين
        على المنصة، كما تعمل على فتح منافذ وأسواق داخلية وخارجية لمنتجات العطارة
        عالية الجودة وذلك من خلال توفير لوحة تحكم لكل تاجر او / مورد مسجل في سوق
        العطارة تمكنه من عرض منتجاته او أو خدمته للعملاء وتوفير أنظمة فعالة
        لمعالجة الشكاوى وتفعيل الدور الرقابي بما يضمن ضمان حقوق التجار أو/
        الموردين ورضاء العملاء في آن واحد. المورد: هو التاجر الذي يقوم بعرض
        منتجاته عبر تطبيق سوق العطارة بغرض بيعها بالجملة للتجار المسجلين في سوق
        العطارة. التاجر: من يتقدم إلى المنصة بحساب تاجر بغرض بيع المنتجات
        بالتجزئة للعملاء سواء كان مصدرها من الموردين المسجلين في سوق العطارة أو
        غيره. الموزع/السائق: هو المفوض من قبل المورد أو التاجر للتوصيل إلى صاحب
        الطلب سواء كان توريد إلى متجر التاجر أو من التاجر إلى العميل. العميل
        (المستخدم): هو كل شخص يقوم بالطلب بشراء أي منتج من منتجات العطارة
        المتاحة من خلال متاجر التجار المتاحة على التطبيق و تُقدم إليه أحد
        المنتجات التي قام بطلب شرائها من سوق العطارة لإشباع احتياجاته.
        الاتفاقية: يقصَد بها قواعد وشروط وأحكام استخدام التجار او أو الموردين
        تطبيق سوق العطارة، أي كافة أحكام وشروط هذه الاتفاقية، والتي تحكم وتنظّم
        العلاقة فيما بين أطراف هذه الاتفاقية. تهدف هذه السياسة الخاصة بمستخدمي
        تطبيق سوق العطارة إلى تقديم خدمة الدفع الإلكتروني وتوضيح مدى جودة مستوى
        الخدمات المقدمة من خلال الأنظمة وتنوع هذه الخدمات، كما تهدف هذه السياسة
        لإيضاح الحقوق والواجبات لكل مستخدمي تطبيق سوق العطارة أو موقعها
        الالكتروني وعملائها، دون إجحاف بأي اتفاقيات أخرى قد تشملها عملية تقديم
        تلك الخدمة وتُعد هذه السياسة بمثابة اتفاق بين سوق العطارة وبين كل من
        يتعامل معها، وفي حال تسجيلك، أو استمرارك في استخدام الخدمات أو الوصول
        إليها، فأنت توافق على الالتزام بشروط الاستخدام والوثائق القانونية بأثر
        فوري. ولذا يعدُّ مجرد الحصول على أي خدمة من الخدمات المشمولة بهذه
        الاتفاقية، موافقة ضمنية، على جميع ما ورد في هذه السياسة. أهلية التاجر
        القانونية: 1. يقر المورد او / التاجر بأنه ذا أهلية قانونية معتبرة شرعاً
        وقانوناً، وأن عمره لا يقل عن ثمانية عشرة عاماً، كما هو معتبر في القوانين
        والأنظمة المرعية بالمملكة العربية السعودية، وأنه ذا دراية كافية لإنشاء
        حساب على تطبيق سوق العطارة لتمكنه من عرض منتجاته وإدارة متجره الالكتروني
        عبر المنصة. 2. يقر المورد او /التاجر بأنه ذا أهلية سليمة وأنه غير مصاب
        بأي عارض من عوارض الأهلية. 3. في حال قيام التاجر بالتسجيل كمؤسسة أو
        شركة، أو أي شكل نظامي آخر، فإن هذه المؤسسة أو الشركة أو الشكل النظامي
        المسجّل عن طريقه يجب أن تتوافر فيه الأهلية القانونية والنظامية والشرعية
        اللازمة للقيام بأعمال التجارة عبر منصة سوق العطارة. 4. يقر مقدم الخدمة
        (التاجر) رفع المستندات المطلوبة والتي تشمل بطاقة هوية أو بطاقة عمل، وسجل
        تجاري، وبطاقة ضريبية في حالة كونه شخص اعتباري. يوافق المورد او /التاجر
        بأنه في حال مخالفته لهذه المادة ”أهلية التاجر القانونية” فإنه يتحمّل
        تبعات هذه المخالفة أمام العملاء أو الغير، حيث أن منصة سوق العطارة لا
        علاقة لها بتعاملات المورد او /التاجر مع العملاء أو الغير من الناحية
        القانونية والنظامية والشرعية، كما أنه يحق لمنصة سوق العطارة في هذه
        الحالة مساءلة التاجر/المورد بموجب أحكام المسؤولية العقدية، ومطالبته
        بالتعويض عن الأضرار التي قد تنشأ نتيجة هذه المخالفة، ومن قبيل هذه
        الأضرار ما يصيب سوق العطارة في سمعتها أمام التجار الآخرين أو العملاء او
        أمام الغير. 5. التزامات منصة سوق العطارة: 1. إن مهمة منصة سوق العطارة هي
        تقديم وتوفير أدوات الدعم الالكتروني، وذلك عن طريق انشاء إنشاء حساب خاص
        بالمورد/ او بالتاجر يمكنه من عرض منتجاته من خلال متجره الإلكتروني عبر
        المنصة فقط وإتاحة الشراء امام أمام عملاء المنصة، حيث أن التزام منصة سوق
        العطارة بموجب هذه الاتفاقية هو فقط ما تم ذكره بعالية أمام المورد/ او
        التاجر. 2. وقد تلتزم منصة سوق العطارة بتوفير خدمات أخرى للمورد/ او
        للتاجر، ؛ وذلك في حالة رغبتهم في ذلك فقط، كخدمات الدعم التسويقي أو خدمات
        التوجيه والإرشاد، وكذلك قد توفّر لهم بوابات دفع الكترونية عبر
        الأانترنت.، وجميع خدمات منصة سوق العطارة تخضع لمواد وبنود وأحكام هذه
        الاتفاقية. 3. إن جميع التعاملات التي تتم بين المورد او /التاجر والعملاء
        لا علاقة لها بشخص منصة سوق العطارة، ومنصة سوق العطارة غير مسؤولة عنها،
        حيث أن هذا التعامل هو علاقة تعاقدية مستقلة تخضع للاتفاق الذي يبرم بين
        التاجر التجار والعملاء. وبناءً عليه فإذا تخلّف العميل عن سداد ثمن الخدمة
        أو المنتج الذي يوفّره التاجر فلا علاقة لمنصة سوق العطارة بهذه المخالفات.
        4. أنت تعلم أن منصة سوق العطارة تتيح للمورد او/ للتاجر الذي يوافق على
        هذه الاتفاقية عرض خدمات متجره الالكتروني عبر المنصة وبذلك فليس هناك أدنى
        مسؤولية على منصة سوق العطارة حول المخالفات التي يقوم بها المورد/ التاجر
        في متجره بالمخالفة لأحكام هذه الاتفاقية. ضوابط انضمام المورد او/ التاجر
        لمنصة سوق العطارة: 1. يجوز لكل شخص توافرت فيه الأهلية القانونية المعتبرة
        نظاماً وشرعاً عرض متجره وفقاً لقواعد وأحكام اتفاقية الاستخدام وعلى وجه
        الخصوص” أهلية التاجر القانونية “. 2. يجب أن يكون المتجر الخاص بالمورد
        أو/ التاجر والذي تم عرضه وفقاً لاتفاقية استخدام منصة سوق العطارة غير
        مخالف للأنظمة والقوانين المرعية في المملكة العربية السعودية، ويلتزم
        التاجر بتوضيح جميع منتجاته والعروض والخصومات وتخلي منصة سوق العطارة
        مسؤوليتها عن مخالفة المورد أو/ التاجر لأحكام النظام السعودي في المملكة
        العربية السعودية والآداب العامة، و يبقى لمنصة سوق العطارة دائماً الحق في
        رفض تسجيل أي مورد أو تاجر لا يتوافق مع القوانين والأنظمة المرعية في
        المملكة العربية السعودية أو أحكام هذه الاتفاقية، وعليه فإن التاجر يقر
        بموجب أحكام هذه الاتفاقية أن متجره لا يخالف النظام العام في المملكة
        العربية السعودية أو الآداب الإسلامية. 3. يجب أن يكون المتجر الذي تم عرضه
        عن طريق منصة سوق العطارة غير مخالف لقواعد وأحكام هذه الاتفاقية، كما يقر
        التاجر بأن النشاط الذي ينصب عليه التعامل في المتجر الخاص به غير مخالف
        لهذه الاتفاقية وغير مخالف للأنظمة والقوانين المعمول بها في المملكة
        العربية السعودية. 4. لا يحق لأي مورد أو/ تاجر لديه حساب الكتروني لدى
        منصة سوق العطارة إذا ألغيت عضويته أو منع من عرض متجره من قبل منصة سوق
        العطارة أو بموجب أوامر أو أحكام قضائية استخدام المنصة. 5. في حال قيام أي
        مستخدم بالتسجيل كمؤسسة أو شركة، أو مؤسسة خيرية أو جهة اعتبارية، فإن
        المؤسسة أو الشركة أو الجهة المسجّلة تكون ملزمة بكافة الشروط والأحكام
        المذكورة في اتفاقية الاستخدام الخاصة بمنصة سوق العطارة. 6. في حالة كان
        التاجر المتقدم لطلب الانضمام للمنصة وتأسيس متجره وعرض منتجاته يمثل مؤسسة
        تجارية أو شركة أو مؤسسة خيرية أو جهة اعتبارية فلابُد من تزويد منصة سوق
        العطارة بكافة المعلومات والوثائق الثبوتية، مثل السجل التجاري أو التراخيص
        وأي وثائق أخرى للمتجر تطلبها منصة سوق العطارة للتسجيل ولإثبات الشخصية
        القانونية الخاصة بالمتجر. 7. ينبغي على كافة الموردين و/ التُجّار
        الالتزام بكافة القوانين المعمول بها لتنظيم التخفيضات والتجارة عبر
        الإنترنت، وكذلك الالتزام بنظام الجرائم المعلوماتية وأنظمة وزارة التجارة
        والاستثمار ونظام التعاملات الإلكترونية ونظام التجارة الإلكترونية. 8. يقر
        التاجر بأنه قبل تسجيله في منصة سوق العطارة قد تحقق من توافر جميع
        الإجراءات والاشتراطات المطلوبة من قبل الجهات الرسمية في المملكة العربية
        السعودية وقد استوفى جميع هذه الإجراءات والاشتراطات لممارسة نشاطه من خلال
        المنصة. 9. يجب أن يلتزم التاجر بالتزامات التسجيل المحددة في ”الحسابات
        والتزامات التسجيل“ وجميع قواعد وأحكام هذه الاتفاقية. 9. الحسابات
        والتزامات التسجيل كمورد او/ تاجر: فور التقدم بطلب الانضمام إلى منصة سوق
        العطارة كمورد/ أو تاجر تكون مطالباً بالإفصاح عن معلومات محددة ووضع كلمة
        مرور سرية ليتم تنشيط حسابك الالكتروني فسوف تصبح مستخدمًا لخدمات منصة سوق
        العطارة ، وبذلك تكون قد وافقت على الآتي: 1. أن تكون مسؤولاً عن المحافظة
        على سرية معلومات حسابك الإلكتروني وسرية كلمة المرور، وتكون بذلك موافقاً
        على إعلام منصة سوق العطارة بأي استخدام غير مفوض به لمعلومات حسابك
        الإلكتروني لدى منصة سوق العطارة أو أي اختراق آخر لمعلوماتك السرية في
        الحال. 2. لن تكون منصة سوق العطارة بأي حال من الأحوال مسؤولة عن أي خسارة
        قد تلحق بك بشكل مباشر أو غير مباشر معنوية أو مادية نتيجة كشف معلوماتك
        السرية (كاسم المستخدم أو كلمة الدخول المرور) أو في حال إساءة استخدام
        المنصة. 3. أنت تلتزم باستخدام حسابك بنفسك، حيث أنك مسؤولاً عنه مسؤولية
        كاملة، وفي حال استخدام غيرك له فهذه يعني أنك قد فوّضته باستخدام الحساب
        باسمك و لحسابك ما لم تقم كتاجر بإبلاغ إدارة منصة سوق العطارة بعكس ذلك.
        4. أنت تلتزم عند استخدام منصة سوق العطارة أن تستخدمها بكل جدية ومصداقية،
        وأن تلتزم بقواعد وأحكام هذه الاتفاقية، وأن تلتزم وتتطلع على الضوابط
        النظامية والقانونية المعمول بها في المملكة العربية السعودية، وتعتبر
        ملزماً بتعويض منصة سوق العطارة عن أي خسائر مباشرة أو غير مباشرة قد تلحق
        بها نتيجة أي استخدام غير قانوني أو غير شرعي أو غير حقيقي أو غير مفوض
        لحسابك من طرفك أو من طرف أي شخص آخر استطاع الدخول إلى حسابك سواء كان
        لإنجاز الخدمات باستعمال اسم المستخدم وكلمة المرور أو نتيجة لإهمالك،
        وسواء بتفويض منك أو بلا تفويض. 5. أنت تلتزم بالإفصاح عن معلومات حقيقية
        وصحيحة ومحدثة وكاملة وقانونية عن نفسك كمورد/ او كتاجر وعن تجارتك
        ومنتجاتك حسبما هو مطلوب أثناء التسجيل لدى منصة سوق العطارة، وتلتزم
        بتحديث بياناتك في حال تغييرها في الواقع أو في حال الحاجة إلى ذلك. 6. إن
        منصة سوق العطارة تلتزم بالتعامل مع معلوماتك الشخصية وعناوين الاتصال بك
        بسريّة تامة وفقاً لأحكام سياسة الخصوصية وسرية المعلومات المعمول بها،
        والتي تلتزم بالإاطلاع والموافقة عليها لكي يتم إانشاء حسابك الإلكتروني
        لدى منصة سوق العطارة كمورد/ او كتاجر. 7. سوف تكون ملزمًا بالحفاظ على
        بيانات التسجيل وتحديثها دائمًا للإبقاء عليها حقيقية وصحيحة وراهنة وكاملة
        وقانونية، وإذا أفصحت عن معلومات غير حقيقية أو غير صحيحة أو غير راهنة أو
        غير كاملة أو غير قانونية او أو مخالفة لما جاء في هذه الاتفاقية، فإن منصة
        سوق العطارة تمتلك الحق دون الرجوع لك كمورد أو/ تاجر في وقف أو تجميد أو
        إلغاء حسابك الالكتروني في المنصة، وذلك دون إلحاق الضرر بحقوق منصة سوق
        العطارة الأخرى ووسائلها المشروعة في استرداد حقوقها وحماية باقي الموردين
        والتجار والمستخدمين لمنصة سوق العطارة. 8. لمنصة سوق العطارة الحق في أي
        وقت في أن تجري أي تحقيقات تراها ضرورية سواء كانت مباشرة أو عبر طرف ثالث
        وتطالبك بالإفصاح عن أي معلومات إضافية أو وثائق مهما كان حجمها لإثبات
        هويتك أو ملكيتك لأموالك أو للحساب الخاص بك. 9. في حالة عدم الالتزام بأي
        مما ورد أعلاه فإن لإدارة منصة سوق العطارة الحق في إيقاف أو إلغاء حسابك
        الإلكتروني وبالتالي متجرك أو تجميده دون الرجوع اليك إليك كمورد أو/ تاجر.
        10. تحتفظ منصة سوق العطارة بالحق في إلغاء أي حسابات غير مؤكدة وغير مثبتة
        أو عمليات أو حسابات مر عليها مدة طويلة دون نشاط. 11. يلتزم كلاً من
        التاجر والمورد بإضافة سياسة الشروط والأحكام التي تنطبق على متاجرهم في
        سوق العطارة. الاخطارات الإلكترونية ووسائل التواصل الرسمية: 1. توافق على
        أن يتم التواصل معك عبر البريد الإلكتروني المسجل من خلالك، أو من خلال
        قيام إدارة منصة سوق العطارة ببث رسائل عامة ترد إلى كافة الموردين أو/
        التجار. 2. كما توافق على أن جميع الإاتفاقيات والإعلانات والبيانات
        والإاتصالات الأخرى التي نزودك بها إالكترونياً تقوم مقام مثيلاتها
        المكتوبة، وهي حجة قائمة بذاتها، في تلبية الاحتياجات القانونية. 3. بإمكان
        منصة سوق العطارة خلال فترة عضويتك وتجارتك لدى المنصة بإرسال رسائل
        إلكترونية ترويجية لتعريفك بأي تغيرات أو إجراءات أو نشاطات دعائية جديدة
        قد تضاف إلى منصة سوق العطارة. 3. التعديلات على اتفاقية الاستخدام
        والرسوم: 1. أنت تعلم وتوافق على أن منصة سوق العطارة قد تقوم بإعلامك عن
        أي تعديل على هذه الاتفاقية كما هو مذكور بعالية، والأصل هو أن تقوم
        بمراجعتها والتحقق من أي تغييرات تطرأ عليها وبموجب التعديل إما أن تتضاعف
        التزاماتك أو تتضاءل حقوقك وفقاً لأي تعديلات قد تجري على هذه الاتفاقية.
        2. أنت توافق على أن منصة سوق العطارة تملك بمطلق صلاحيتها ودون تحملها
        المسؤولية القانونية أن تجري أي تعديلات أساسية أو فرعية على هذه
        الاتفاقية، وقد يتم إعلام الموردين أو التجار عن هذا التعديل بأي وسيلة
        تقنية يتم توفيرها، وقد يتم ذلك عن طريق البريد الإلكتروني أو ببث رسالة
        عامة لكافة الموردين أو التجار، ويكون قبول هذا التعديل قانوني ونافي
        للجهالة ومُلزِم للمورد أو التاجر ولأحكام الاتفاقية بما فيها من تعديلات.
        3. في حال الاعتراض على أي تعديل على هذه الاتفاقية فإن ذلك قد يؤثر على
        استمرارك في التسجيل، حيث أنه للاستفادة من خدمات منصة سوق العطارة يجب
        الموافقة على هذه الاتفاقية وعلى أي تعديل يطرأ عليها، ولذلك في حالة عدم
        قبول التعديل تتوقع منصة سوق العطارة توقفكم عن استخدام خدماتها حيث أن
        مجرد دخولكم إلى حسابكم في منصة سوق العطارة أو استخدامكم له يُعد قبولًا
        منكم للتعديلات وموافقة كاملة تامة نافية للجهالة، ويسر منصة سوق العطارة
        الإجابة على استفساراتكم بشأن هذه الاتفاقية واستقبال أي اقتراحات. 4. كافة
        الرسوم تحتسب بالريال السعودي، وعليك دفع كافة الرسوم المستحقة على المنصة
        مضافاً إليها أي نفقات أخرى تضيفها منصة سوق العطارة. 5. قد تفرض منصة سوق
        العطارة رسومًا على الموردين او/ التُجّار إاذ يعتمد على العروض أو الباقات
        التي ينضمون إليها. 6. تحتفظ منصة سوق العطارة بحقها في إضافة أو زيادة أو
        خفض أو خصم أي رسوم أو نفقات بموجب قواعد وأحكام هذه الاتفاقية، على أي من
        الموردين أو/ التجار أي كان سبب تسجيلهم. 7. يلتزم المورد أو/ التاجر بعرض
        قائمة تتضمن جميع المبالغ والأرباح التي نشأت عن متجره على لوحة التحكم
        الموفرة له من قبل منصة سوق العطارة ، ويلتزم أن يعرض في هذه القائمة جميع
        عمليات الشراء التي تمت من خلال المنصة مع بيان نوع المنتجات وكمياتها
        وأوصافها وقيمتها، وعليه فإن المورد أو/ التاجر يلتزم بتوفير المواصفات
        المحاسبية المطلوبة، تطبيقاً لأحكام هذه الاتفاقية، ولما في هذا التنظيم من
        مصالح قانونية واقتصادية وتجارية للموردين أو/ التجار، وفي حالة مخالفة
        المورد/ او التاجر لأحكام هذا البند فإنه يُعَد مسؤولاً عن أي أضرار قد
        تنشأ نتيجة هذه المخالفة. 7. استخدام معلوماتك الشخصية ومعلومات تفاصيل
        العمليات: 1. لا مانع لديك بأن تمنح منصة سوق العطارة حقاً غير محدود،
        ودائم وغير قابل للإلغاء، ومعفي من المصاريف، ومرخص باستخدام معلومات أو
        مواد شخصية أو غير ذلك مما وفرتها أو زودت بها المنصة أو أعلنتها على
        التطبيق من خلال انضمامك إليها أو عرضتها من خلال متجرك، وذلك عبر النماذج
        المخصصة للتواصل والتسجيل، أو عبر أية رسالة إلكترونية أو أي من قنوات
        الاتصال المتاحة بالتطبيق، وذلك بهدف تحقيق أي من المصالح التي تراها
        المنصة. 2. أنت الوحيد المسؤول عن المعلومات التي قمت بإرسالها أو نشرها
        وينحصر دور منصة سوق العطارة بالسماح لك بعرض هذه المعلومات عن طريق
        التطبيق/لوحة التحكم لمتجرك ومن خلال قنواتها الإعلانية. 3. تخضع سرية
        معلومات المتاجر المتاحة عبر منصة سوق العطارة لقواعد "سياسة الخصوصية"
        الخاصة بمنصة سوق العطارة. 3. تعهد المورد أو/ التاجر بـالالتزام بالقوانين
        والأنظمة المعمول بها في المملكة العربية السعودية: 1. يتعهّد المورد أو
        التاجر بالالتزام بكافة القوانين والأنظمة المعمول بها داخل المملكة
        العربية السعودية حيال منتجاته أو/و أثناء استخدامه لمنصة سوق العطارة ،
        وكذا القوانين والشروط والأحكام المعمول بها والمُنظِّمة لاستخدام شبكة
        الإنترنت وهذه الاتفاقية وسياسة الخصوصية المعمول بها لدى منصة سوق
        العطارة. 2. في حال مخالفة أي من الموردين أو التجار لما ورد في البند
        بعالية فإنه يقر بحق منصة سوق العطارة في اتخاذ أي من الإجراءات التالية
        وهي على سبيل المثال لا الحصر: التنبيه أو إيقاف الخدمة أو النظر في إغلاق
        المتجر. 2. --- الملكية الفكرية: 1. أن ملكية الحقوق الفكرية والعلامات
        التجارية والأسعار والأسماء التجارية وعلامات الخدمة التي تستخدم من قبل
        سوق العطارة في الموقع الإلكتروني تخُص سوق العطارة، ولا يتم تفويض أي شخص
        باستخدام أي ملكية فكرية لها، أو حقوق طبع أو علامة بأي طريقة دون طلب إذن
        كتابي مسبق من صاحب العلامة التجارية. ولا يجوز نقل أو نشر أو نسخ أو تعديل
        أو عرض أو استخدام المواد المعروضة على موقع سوق العطارة بدون إذن صريح
        مسبق. 2. تحترم إدارة منصة سوق العطارة حقوق الملكية الفكرية الخاصة
        بالموردين او /بالتجار والتي كوّنوها عبر متاجرهم المعروضة من قبل منصة سوق
        العطارة، سواءً كانت مملوكة لهم قبل تسجيل حسابهم الإلكتروني لدى المنصة أم
        بعد التسجيل. 3. يحترم المورد او/ التاجر حقوق الملكية الفكرية الخاصة
        بمنصة سوق العطارة، والكلمات والشعارات والرموز الأخرى الخاصة بمنصة سوق
        العطارة، حيث أن منصة سوق العطارة، وكل حق يتبع منصة سوق العطارة، هي حقوق
        محمية بموجب حقوق الملكية الفكرية وقوانين العلامات التجارية المعمول بها
        في المملكة العربية السعودية وتعد ملكية خالصة لمنصة سوق العطارة ولا يحق
        بأي حال من الأحوال التعدي عليها أو استخدامها دون تفويض من إدارة منصة سوق
        العطارة. 4. لا تتحمل منصة سوق العطارة المسئولية في حال التعدي على حقوق
        الملكية الفكرية المملوكة للموردين او/ التجار المسجلين والأعضاء على
        المنصة. 4. حقوق العملاء: ● حرصًا من منصة سوق العطارة على حماية حقوق
        العملاء، على الرغم من أن العملاء ليسوا طرفاً في هذه الاتفاقية، وتحقيقًا
        لأحد أهم أهداف منصة سوق العطارة بتقديم خدمة ذات جودة عالية للجميع، ومن
        باب ضمان إستلام العملاء لكافة مشترياتهم بشكل سليم فإن منصة سوق العطارة
        توضّح للتاجر؛ ما يلي:- 1. في حال قام العملاء بشراء أحد المنتجات أو
        العروض أو الخصومات المتوفرة من خلال أحد متاجر التجار او/ الموردين، ولم
        يتسلم العميل طلبه أو تسلم طلب يختلف عن ما عرض من مواصفات ذكرت بصفحة
        المورد أو/ التاجر أو الخصم المختار، حينها يكون من حق العميل إبلاغ أو
        تقديم شكوى لإدارة منصة سوق العطارة ولمنصة سوق العطارة الحق في اتخاذ ما
        تراه مناسبًا حيال المورد/ أو التاجر الذي خالف قواعد هذه الاتفاقية، وذلك
        وفقاً لقواعد هذه الاتفاقية. 2. منصة سوق العطارة غير مسؤولة قانوناً أو
        شرعاً عن أي إخلال حاصل من قبل المورد أو التاجر في مواجهة العميل، حيث
        إنها ليست طرفاً في العلاقة بين العملاء والتجار أو الموردين، ولكنها تقوم
        بذلك لدواعي تحسين جودة خدمات منصة سوق العطارة والتجار والموردين
        المتواجدين في المنصة. 3. للعملاء مراجعة مشترياتهم قبل الإاستلام للتأكد
        من أنها سليمة ومطابقة لما تم شرائه، حيث أن منصة سوق العطارة غير مسؤولة
        عن العلاقة التي بين العملاء والتجار أو /الموردين. إن منصة سوق العطارة لا
        تلتزم بأي حال من الأحوال وفق هذه المادة وهذا البند باتخاذ ردّة فعل تجاه
        شكوى العميل وإنما تخضع شكوى العميل لردّة فعل المنصة وفقًا لما تراه
        مناسبًا، ووفقاً لقواعد هذه الاتفاقية، وبما يحافظ على مصلحتها وسمعتها
        التجارية والاقتصادية، وعلى كل حال فإن منصة سوق العطارة قدّ تتخذ بعض
        الإجراءات تجاه المورد أو التاجر وذلك فقط في حالة طلب العميل، اتخاذ إجراء
        حيال المورد أو التاجر وذلك في حالة إنه قام بأي من الأفعال المسببة لقيام
        العميل بتقديم شكوى ضده من خلال المنصة، ومن ضمن هذه الأفعال على سبيل
        المثال لا الحصر التاجر أو المورد الذي تعمّد خداع أو تظليل أو غش العميل
        ،...إلخ، فيحق للعميل الطلب من منصة سوق العطارة اتخاذ أي من الإجراءات
        الأتية: أما إلغاء/محو التاجر من خلال منصتها، تجميد عضويته أو إلزامه
        بتعويض العميل، وذلك انطلاقًا من رغبة منصة المنصة في اتخاذ ما تراه مناسب،
        من أجل الحفاظ وحماية حقوق كافة الموردين/ او التجُار والعملاء. مسؤولية
        منصة سوق العطارة: 1. منصة سوق العطارة غير مسؤولة بأي شكل من الأشكال عن
        أي أداء غير مرض أو متأخر من قبل الموردين أو/ التجار أو عدم التزام
        العملاء بالسداد، ولا عن أي خسائر، أو أعطال أو تأخير، بسبب منتجات غير
        متوفرة أو تأخر تسليمها أو عدم جودة الخدمة المقدمة من قبل المورد أو/
        التاجر. 2. منصة سوق العطارة لا تتحمل أي مطالبات تنشأ عن أخطاء أو إهمال،
        سواء كانت ناتجة بشكل مباشر أو غير مباشر أو بشكل عرضي أو عن طريق طرف
        ثالث. 3. منصة سوق العطارة أو مسؤوليها أو عملائها أو مُلّاكها غير مسؤولين
        عن أي مطالبات أو نزاعات أو تكاليف أو أضرار أو أي مسؤولية أو أي خسارة
        مباشرة أو غير مباشرة لأي طرف نشأت عن فعل قام به أحد العملاء للمنصة. 4.
        منصة سوق العطارة وعملائها وملّاكها ومن يمثّلها لا علاقة لهم بأي نشاط غير
        شرعي أو غير نظامي يقوم به المورد او التاجر، أو أي نشاط يخالف الأنظمة
        والتعليمات المرعيّة في المملكة العربية السعودية، حيث تنحصر مسؤولية منصة
        سوق العطارة فقط في توفير وتقديم أدوات الدعم الالكتروني المتمثّل في عرض
        منتجات المورد او التاجر وعروضه وخصوماته من خلال المنصة، وذلك وفقاً
        لتقدير الموردين او التجار المطلق وما يتبع ذلك من خدمات دعم الكتروني مقدم
        من المنصة مثل لوحة التحكم. 5. تنوّه إدارة منصة سوق العطارة إلى أنها في
        حال ملاحظاتها لأي أنشطة مشبوهة يجري التخطيط لارتكابها أو جاري ارتكابها
        أو جرت على منصة سوق العطارة فإنها سوف تتقدّم ببلاغ السلطات المختصة عن
        هذه الأنشطة، وهي غير مسؤولة بأي حال من الاحوال عن هذه المخالفات. 5.
        مسؤولية التاجر/المورد تجاه منصة سوق العطارة: يوافق التاجر أو /المورد على
        تحمل المسؤولية، ووقاية منصة سوق العطارة من أي ضرر قد يقع عليها من جراء
        مخالفات التاجر أو/ المورد، كما يلتزم برفع أو منع أي ضرر قد يلحق بمنصة
        سوق العطارة من جراء مطالبات أو خسائر أو أعطال أو تكاليف أو نفقات أو رسوم
        نتجت بسبب إساءة استخدامه أو سوء استخدام العميل ونتج عن ذلك خرق لهذه
        الاتفاقية، أو الأنظمة والقوانين المرعية بالمملكة العربية السعودية أو
        تعدي على حقوق أحد التجار الأخرين أو شكوى من أحد العملاء أو أطراف ثالثة.
        سرية المعلومات: 1. تحيطكم منصة سوق العطارة علمًا بأن شبكة الإنترنت ليست
        وسيلة آمنة، وسرية المعلومات الشخصية لا يمكن أن تكون مضمونة 100% على شبكة
        الإنترنت. 2. إذ تتخذ منصة سوق العطارة معايير (ملموسة وتنظيمية وتقنية)
        ذات جودة عالية لحماية الموردين/ او التجُار المستخدمين لها ومنع وصول أي
        شخص غير مفوض إلى معلومات الموردين أو/ التجار وحفظها. 3. منصة سوق العطارة
        ليست لها سيطرة على أفعال أي طرف ثالث أو الغير مثل صفحات الانترنت الأخرى
        الموصولة عن طريق روابط داخل التطبيق المنصة أو أطراف ثالثة تدَعي أنها
        تمثلك وتمثل آخرين. 4. أنت تعلم وتوافق على أن منصة سوق العطارة قد تستخدم
        معلوماتك التي قمت بالإفصاح عنها، بهدف تقديم الخدمات لك، ولإرسال رسائل
        تسويقية، وأن سياسة الخصوصية في منصة سوق العطارة تضبط عمليات الجمع
        والمعالجة والاستخدام والتحويل لمعلومات هويتك الشخصية، وتخضع قواعد سرية
        المعلومات لـ "سياسة الخصوصية" الخاصة بمنصة سوق العطارة والمنشورة في
        الموقع الالكتروني الخاص بها. 4. إلغاء عضوية المورد او التاجر: ● إن منصة
        سوق العطارة بحسب اتفاقية الاستخدام وبحسب القوانين والأنظمة المرعية
        بالمملكة العربية السعودية قد تلجأ إلى وقف مؤقت أو دائم أو سحب وإلغاء
        الحساب الإلكتروني للمورد او/ للتاجر أو تحديد قدراتهم للوصول إلى خدمات
        المنصة، في حالة: 1. انتهاك قواعد وأحكام هذه الاتفاقية. 2. إذا لم يكن
        بإمكان منصة سوق العطارة توثيق أي من معلومات المستخدم المقدمة إليها. 3.
        إذا قررت منصة سوق العطارة أن نشاطات المورد او/ التاجر مخالفة للقانون أو
        أن نشاطاتهم المورد أو التاجر قد تسبب للموردين او/ للتجار الآخرين أي
        متاعب أو مخالفات قانونية. 4. إن منصة سوق العطارة لا تتنازل عن حقها في
        اتخاذ الإجراءات المناسبة حيال أي انتهاك يحدث لقواعد وأحكام هذه الاتفاقية
        ولغيرها من أفعال الانتهاك المشابهة، كما إن منصة سوق العطارة لا تلتزم
        باتخاذ أي إجراءات معينة ضد أي انتهاك يحدث لهذه الاتفاقية وإنما يخضع هذا
        الأمر لتقدير إدارة منصة سوق العطارة وإدارتها القانونية. 4. عمليات الدفع
        والبيع والشراء: 1. يلتزم المورد أو/ التاجر بإدارة منتجاته وخصوماته
        وعروضه في متجره بشكل جيد، بما يحافظ على الحقوق المكفولة للعميل والتي لا
        ينشئ عنها نزاعًا بينهم. 2. يمكن للتاجر المستخدم للمنصة من إجراء عمليات
        الشراء من المورد إذ يعمل سوق العطارة عمل المتجر الالكتروني لكلاً من تجار
        اللحوم العطارة والموردين. 3. ويمكن للعميل أما الدفع عن طريق بطاقات
        الائتمان أو بطاقات الدفع الخاصة به عبر الانترنت من خلال موقعنا أو
        تطبيقنا. 4. وأنكم كتجار أو /موردين موافقين صراحة على الالتزام بشروط
        الاستخدام، ,وأان تحويل مقابل عمليات الشراء من خلال موقعنا سيتم بعد خصم
        النسبة المتفق عليها مسبقاً لصالح المنصة بالإضافة إالى أية مبالغ متعلقة
        بالضرائب أو أي مصاريف التحويل. 5. نحتفظ بالحق في تعديل أو إزالة أي رسوم
        وفقًا لتقديرنا الخاص، قد نقدم أيضًا عروضًا ترويجية وخصومات والتي ستغير
        المبلغ المدفوع؛ ولكن تخضع فقط لشروط وأحكام هذا العرض الترويجي أو الخصم
        وبعد موافقة التاجر او/ المورد. 6. يتم حساب تسعيرة المنتجات بالعملة
        المحلية (الريال السعودي) مضافاً إليها ضريبة القيمة المضافة، وتكون
        مسؤولية تحديد الأسعار وما تشمله من ضريبة أو أي رسوم مسؤولية صاحب الحساب
        المتجر (التاجر/ المورد). 7. يوافق التاجر أو/ المورد ويقر بإنه لن يقوم
        بعمليات غير حقيقية أو متلاعبة على منصة سوق العطارة ، ولن يستخدم اسم غير
        حقيقي أو أي معلومات شخصية غير حقيقية، حيث أن منصة سوق العطارة لها الحق
        في اتخاذ الإجراءات القانونية المناسبة ضد كل من يقوم بعمليات احتيالية من
        هذا النوع. 8. تحتفظ منصة سوق العطارة بحقها في فرض أي رسوم على التجّار أو
        المتاجر نتيجة لعملياتهم داخل منصة سوق العطارة ، مع إخطارهم بذلك. 9. إذا
        كانت عملية الشراء تظهر علامات احتيال، إساءة، أو نشاط مشبوه، فمن الممكن
        لسوق العطارة أن تقوم بإلغاء العملية المتوافقة مع اسمك أو بريدك
        الإلكتروني أوحسابك، إلى جانب القيام بإلغاء أي حسابات مرتبطة بسوق العطارة
        ، بالإضافة إلى ذلك قد يقوم الموقع بالتحقق من بطاقة الائتمان الخاصة بك
        إذا كنت قد أجريت أي نشاط احتيالي، ونحتفظ بالحق باتخاذ أية إجراءات
        قانونية و/أو مدنية ومن الممكن أن تكون مسؤولاً عن أي خسائر نقدية للموقع،
        من ضمنها تكاليف التقاضي والأضرار، للقيام بإلغاء العملية أو تجميد الحساب.
        9. المحتويات والمنتجات غير المسموح بها: 1. كمورد أو/ تاجر لدى منصة سوق
        العطارة أنت تلتزم بألا تسمح بإعلان أو عرض لأي محتويات عبر متجرك المتاح
        على المنصة تخالف سياسة الخصوصية وسرية المعلومات أو تخالف قواعد وأحكام
        هذه الاتفاقية. 2. تمنع منصة سوق العطارة منعًا باتًا وضع خصومات وعروض على
        المنتجات والخدمات التالية وهي على سبيل المثال لا الحصر: أي منتج/خدمة
        تحتوي على مقامرة، صناديق الحظ أو منتج عشوائي، أي منتج/خدمة قد تؤدي إلى
        أي أذى جسدي أو نفسي بأي شكل من الأشكال، أي محتويات أو اشتراكات مقرصنة
        وكل ما يخالف حقوق الملكية الفكرية والنشر، أي منتج/خدمة جنسية أو إباحية
        أو تدعو لذلك. 2. نظام الجرائم المعلوماتية السعودي: يلتزم التجُار أو
        /الموردين بعدم مخالفة أي من أحكام نظام الجرائم المعلوماتية السعودي، وفي
        حال مخالفتهم لأحكام نظام الجرائم المعلوماتية السعودي فإن هذا يخضع
        لمسؤوليتهم المحضة ولإردادرتهم، وليس هناك أي مسؤولية تقع على عاتق منصة
        سوق العطارة من جراء مخالفة المتجر الخاص بأي تاجر أو /مورد للأنظمة
        المرعية، حيث أن التاجر أو/ المورد هو المسؤول عن متجره وعن تعامله مع
        العملاء مسؤولية كاملة، ولمنصة سوق العطارة دائماً الحق في اتخاذ ما تراه
        مناسباً بشأن أي تاجر أو/ مورد يخالف أحكام نظام الجرائم المعلوماتية
        السعودي فيما يخص حسابه الإلكتروني على المنصة، سواءً عن طريق إبلاغ
        السلطات الرسمية أو الاكتفاء بإغلاق صفحاته أو لوحة التحكم الخاصة به على
        منصة سوق العطارة أو إلغاء حساب التاجر/ او المورد الإلكتروني لدى منصة سوق
        العطارة. الإشعارات بين منصة سوق العطارة والتجار أو/ الموردين : في حالة
        رغبة التجار أو/ الموردين في إرسال أي إشعارات إلى منصة سوق العطارة ،
        عليهم أن يقوموا بإرسالها من خلال البريد الإلكتروني الذي تم توفيره عند
        التسجيل وذلك إلى البريد الإلكتروني الخاص بالمنصة، وتقوم منصة سوق العطارة
        بالرد على الرسالة الإلكترونية. أنت كتاجر توافق على أن أي إشعارات ترسل
        إليك من منصة سوق العطارة سوف تُسلّم إليك بواسطة البريد الإلكتروني الذي
        قمت بتزويد منصة سوق العطارة به خلال عملية التسجيل. إحالة الحقوق
        والالتزامات: أنت كتاجر أو /مورد لا تملك الحق في الاعتراض على تصرفات
        إدارة منصة سوق العطارة حيال منصة سوق العطارة سواء كانت هذه التصرفات على
        سبيل المثال لا الحصر: تمس كيان منصة سوق العطارة أو ملكيتها مثل الدمج أو
        الاستحواذ، أو التزاماتها أو مسؤولياتها أو تصرفات تقنية أو تصرفات إدارية
        تتعلّق بمنصة سوق العطارة ، وليست منصة سوق العطارة مُلزَمة بإبلاغك،
        ولمنصة سوق العطارة إن رأت أهمية إبلاغك عن أي من هذه التصرفات أن تقوم
        بإبلاغك وفقاً لإرادتها المحضة، وذلك بحسب قواعد وأحكام هذه الاتفاقية. حل
        الخلافات: بموجب قواعد وأحكام هذه اتفاقية الاستخدام فإنه في حال النزاع
        فيتم حل الخلاف عن طريق التوفيق والمفاوضات أو التسوية الودية، وفي حال
        استمرار الخلاف فيتم حله عن طريق السلطات المختصة بالمملكة العربية
        السعودية. القانون المعمول به والاختصاص: يخضع إبرام وتنفيذ وتفسير هذه
        الاتفاقية وتسوية النزاعات المتعلقة بها لقوانين المملكة العربية السعودية،
        وأي نزاع على ‏محتوى الموقع يخضع للاختصاص الحصري ‏للمحاكم في مدينة الرياض
        لدى المملكة العربية السعودية ‏بغض النظر عن موقعك الجغرافي‎. ● وإذا اعتبر
        أي حكم في هذه الاتفاقية باطلًا أو غير قابل للتنفيذ، جاز تفسير المصطلح أو
        الشرط من خلال القوانين المعمول بها، فإنه لن يؤثر على باقي أحكام هذه
        الاتفاقية. ● تشكل هذه الاتفاقية كامل اتفاق الأطراف وتفاهمها فيما يتعلق
        بالموضوع الوارد فيه وبه، وتحل محل جميع الاتفاقات أو الوعود أو العهود أو
        الترتيبات أو الرسائل أو الإقرارات أو الضمانات السابقة، سواء كانت شفوية
        أو خطية، ونحن نحتفظ بحق التفسير النهائي لهذا الاتفاق. ● الإجراءات
        المحظورة: فيما يتعلق باستخدامك كتاجر أو/ مورد لمنصة سوق العطارة ومشاركتك
        في الخدمات، فإنك توافق على أنه أثناء استخدامك للتطبيق أو استخدام
        الخدمات، لن تقوم بما يلي: ● انتحال شخصية أي شخص أو كيان بطريقة احتيالية.
        ● مطاردة أو تهديد أو مضايقة أي شخص أو حمل أي أسلحة. ● تنتهك أي قانون أو
        تشريع أو مرسوم أو لائحة. ● تتدخل أو تعطل الخدمات أو الخوادم أو الشبكات
        المتصلة بمنصة سوق العطارة الأساسية. ● استخدام تطبيق أو خدمات منصة سوق
        العطارة بأي طريقة تنتهك حقوق أي طرف ثالث، بما في ذلك على سبيل المثال لا
        الحصر: حقوق الملكية الفكرية أو حقوق النشر أو براءات الاختراع أو العلامات
        التجارية أو الأسرار التجارية أو حقوق الملكية الأخرى أو حقوق الدعاية أو
        الخصوصية. ● نشر معلومات أو التفاعل على تطبيق أو خدمات منصة سوق العطارة
        بطريقة خاطئة أو غير دقيقة أو مضللة (بشكل مباشر أو عن طريق الحذف أو الفشل
        في تحديث المعلومات) أو تشهيرية أو مسيئة أو فاحشة أو بذيئة أو ذات توجه
        جنسي أو تهديد أو مضايقة أو غير قانونية. ● نشر أو إرسال بريد إلكتروني أو
        نقل أي تعليمات برمجية أو ملفات أو برامج ضارة مصممة لمقاطعة أو إتلاف أو
        تدمير أو تقييد وظائف أي برنامج كمبيوتر أو جهاز أو معدات اتصالات أو
        اعتراض أو مصادرة أي نظام أو بيانات. ● الارتباط بشكل مباشر أو غير مباشر
        بأي مواقع أخرى. ● نقل أو بيع حساب المستخدم وكلمة المرور إلى الغير. ●
        تسبب في مشاركة للغير في الإجراءات المحظورة أعلاه. سياسة الخصوصية: سنلتزم
        بجميع الأنظمة واللوائح المعمول بها الملزمة بحماية البيانات من وقت لآخر
        فيما يتعلق بالبيانات الشخصية التي نجمعها منك، بالإضافة إلى ذلك، سنلتزم
        بشروط سياسة الخصوصية الخاصة بنا، بالإضافة إلى أي شروط عمل أخرى سارية
        ووثائق تعاقدية أخرى. أحكام عامة: ● هذه الاتفاقية تعدل بين حين وآخر بحسب
        مقتضى الحال – وتُشكّل آلية العمل والتفاهم والاتفاق والتعاقد - بين التاجر
        او /المورد ومنصة سوق العطارة فقط لا غير، ويلتزم التاجر او/ المورد
        بالالتزام بأحكام هذه الاتفاقية، كما يوافق التاجر او/ المورد على أن يوضع
        في عين الاعتبار ما يلي: 1. هذه الاتفاقية، سارية على كافة التجُار او/
        الموردين لدى لمنصة سوق العطارة وهي المُنظّمة للعلاقة وهي العقد فيما
        بينهم "التجار ومنصة سوق العطارة " ما لم يوجد إتفاق أخر يقضي بغير ذلك،
        بغض النظر عن أي شكل نظامي أو كيان قانوني أو مؤسسي أو تجاري أو خيري يتخذه
        التاجر. 2. أما فيما يخص العلاقة فيما بين التاجر والعميل فإنها تخضع
        لعلاقة قانونية مستقلة ولها ضوابطها الخاصة الجارية فيما بينهم. 3. يجب على
        التاجر أن يضع اتفاقية الاستخدام الخاصة به، على إنه كي يكون التاجر على
        علم من حق المنصة أن تقوم بمراجعة الاتفاقية الخاصة بالتاجر قبل قبولها
        بالمنصة ولابد ألا تتعارض مع اتفاقية الاستخدام الخاصة بالمنصة وأيضاً على
        أن تكون وفقاً لأحكام الأنظمة والقوانين المعمول بها لدى المملكة العربية
        السعودية، وعلى وجه الخصوص نظام التعاملات الالكترونية ونظام التجارة
        الالكترونية وغير ذلك من الأنظمة التي تتعلّق بنشاطه. 4. ليس من حق أي شخص
        – عدا إدارة منصة سوق العطارة – فرض أي مواد أو بنود أو أحكام في اتفاقية
        الاستخدام الخاصة بمنصة سوق العطارة ، ولمنصة سوق العطارة استقبال اقتراحات
        التجار فيما يتعلّق بهذه الاتفاقية. 5. إذا تمت ترجمة هذه الاتفاقية، لأي
        لغة أخرى على منصة سوق العطارة ، فإن النص العربي لهذه الاتفاقية يظل هو
        الأصل في كافة التعاملات. 6. لا تُلغى أو تُعدل هذه الاتفاقية؛ إلا بموجب
        قرار يصدر من إدارة منصة سوق العطارة. 7. في حال إلغاء أي مادة واردة أو
        بند ورد في اتفاقية الاستخدام هذه، فإن مثل هذا الأمر لا يلغي صلاحية باقي
        المواد والبنود والقواعد والأحكام الواردة في اتفاقية الاستخدام وتظل سارية
        حتى إشعار آخر من إدارة سوق العطارة. 8. تحتفظ سوق العطارة بالحق في اتخاذ
        كل الخطوات الضرورية لضمان السرية، الأمان وسلامة النظام مع الاهتمام
        بعملائنا وحماية معلوماتهم، وتحقيقاً لهذه الغاية من الممكن أن نتخذ
        إجراءات متنوعة للتحقق والتأكد من صحة الطلبات التي وضعت من قبلك، إذا كانت
        سوق العطارة وفقاً لتقديرها تقر بأن معاملة الشراء التي قمت بها ليست أو لا
        تظهر بشكل منصف على أنها أصلية أو موثوقة أو يمكن تنفيذها أو صالحة، فمن
        الممكن لها أن تقوم بإلغاء العمليات الخاصة بك في أي وقت قبل الموعد المحدد
        قبل تاريخ المتوقع إلى أي شريك عبر سوق العطارة . 9. هذه الاتفاقية – والتي
        تعدل بين حين وآخر بحسب مقتضى الحال – تُشكّل آلية العمل والتفاهم والاتفاق
        وتلتزم جميع الحسابات بأحكام هذه الاتفاقية. تلقي الشكاوى: في حال وجود
        شكوى بشأن الخدمات المقدمة عبر سوق العطارة ، يأمل منكم سوق العطارة إتباع
        الخطوات التالية: o الخطوة الأولى: التقدم بإفادة شكوى عبر الرابط المخصص
        لذلك في الموقع الإلكتروني لسوق العطارة على أن تتضمّن الإفادة نص الشكوى
        والمستندات المؤيدة لها مع رقم الطلب. o الخطوة الثانية: سيتم العمل على
        إحالة الشكوى من قبل خدمة العملاء الخاصة بسوق العطارة للطرف المعني من أجل
        التعاون في حلها. o الخطوة الثالثة: في حال عدم تجاوب المعني بالشكوى مع
        صاحب الشكوى خلال ثلاث أيام فسوف تتم إفادة صاحب الشكوى بذلك. o الخطوة
        الرابعة: يحق للمستخدم اتخاذ ما يراه مناسباً بعد اطلاعه على مضمون رد
        المعني بالشكوى كما تحتفظ سوق العطارة بالحق في إغلاق المتجر/ الحساب
        المخالف في حال ثبوت المخالفة او اتخاذ ما تراه
      </div>
    </b-modal>
  </div>
</template>
<script>
import joinServices from "../services/join";
import appButton from "../../../components/buttons/appButton.vue";
import getCities from "@/mixins/getCities";
import { core } from "@/config/pluginInit";
import { VUE_APP_FRONT_MARKETPLACE_LINK } from "../../../config/constant";
export default {
  components: { appButton },
  mixins: [getCities],
  data() {
    return {
      allCategories: [],
      loadingRegistrationButton: false,
      termsModal: false,
      selected: null,
      merchant: {
        name: "",
        email: "",
        phone: "",
        country_code: "",
        national_id: "",
        password: "",
        confirm_password: "",
        store_name: "",
        store_address: "",
        accept_terms: false,
      },
    };
  },
  methods: {
    onSelect(data) {
      this.merchant.country_code = data;
    },
    async joinMerchant() {
      if (!this.merchant.accept_terms) {
        core.showSnackbar("error", "يجب الموافقة على الشروط والأحكام");
        return;
      }
      this.loadingRegistrationButton = true;
      try {
        const { data } = await joinServices.joinMerchant({
          ...this.merchant,
          store_city_id: "" + this.city_id,
          store_area_id: "" + this.area_id,
        });
        core.showSnackbar("success", data.message);
        window.location.href = VUE_APP_FRONT_MARKETPLACE_LINK + "?login=true";
      } catch (err) {
        core.showSnackbar("error", err.response.data.message);
      } finally {
        this.loadingRegistrationButton = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.joining-card {
  box-shadow: 0px 3px 31px #0000001c;
}

.joining-select {
  height: 45px !important;
  border-radius: 10px !important;
}

.joining-label {
  font-size: 20px !important;
  margin-bottom: 4px !important;

  div {
    border-radius: 4px !important;
  }
  input {
    &.form-control {
      background-color: #fff !important;
    }
  }
}

.joining-title {
  font-size: 25px !important;
}

.line-one:after {
  position: absolute;
  width: 180px;
  right: 0;
  bottom: -4px;
  content: "";
  height: 1px;
  background: var(--iq-primary);
}

.line-two:after {
  position: absolute;
  width: 120px;
  right: 0;
  bottom: -3px;
  content: "";
  height: 1px;
  background: var(--iq-primary);
}

.vs--searchable div {
  border-radius: 10px !important;
}

.testphone {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
  margin-top: 28px;
}

.phone-input .form-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.heading {
  font-size: 2rem;
  color: #000 !important;
  font-weight: 500;
  @media (max-width: 1300px) {
    font-size: 1.7rem !important;
  }
}

.join-paragraph {
  color: #727272;
  font-size: 1.3rem;
  @media (max-width: 1300px) {
    font-size: 0.8rem;
  }
}

.join-btn {
  border-radius: 4px !important;
}

.join-form,
.hold-heading-back {
  width: 80%;
  // margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
  }
}

.hold-heading-back {
  display: flex;
  justify-content: space-between;
  .back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    @media (max-width: 1300px) {
      font-size: 0.7rem;
    }
    span {
      display: block;
      margin: 0 0.5rem;
    }
  }
}
</style>
